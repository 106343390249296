import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import ReactDom from 'react-dom';
import {stack} from '../filestorage/vars.js'
import Mando from '../../pics/star-wars-boba-fett-mandalorian-logo.png'
import Helmet from 'react-helmet';

var title = "Custom websites and mobile apps development by BCS"
// const Calendar = () => (

class JobOffer extends React.Component {

	constructor() {
		super()
		this.state = {
			hey: 'Me',
			color: 'black',
			title: "Custom websites and mobile apps development by BCS",
			buttonDisabled: false
		}
	}


	handleSubmit(event) {
		this.setState({buttonDisabled:true})
		var that = this
		event.preventDefault()
		const nameInput = event.target.elements.name
		const companyInput = event.target.elements.company
		// const locationInput = event.target.elements.location
		// const remoteInput = event.target.elements.remote
		const jobInput = event.target.elements.project
		const emailInput = event.target.elements.email
		const messageInput = event.target.elements.message
		const humanCheck = event.target.elements.noRobotsAllowed
		// if (humanCheck != 9) { return alert("Anti robot check not passed, please try again Mr. Robot")}
		if (humanCheck.value != 9) {
			that.setState({ color: 'red' })
			return alert("Anti robot check not passed, please try again")
		}

		that.setState({ color: 'black' })
		var data = { name: nameInput.value, company: companyInput.value, email: emailInput.value, job: jobInput.value }
		// console.log("--data--", name: event.target.elements.name.value, event.target.elements.email.value, event.target.elements.message.value)
		axios.post('/jobOffer', data)
		.then((response) => {
			nameInput.value = ""
			emailInput.value = ""
			// locationInput.value = ""
			// remoteInput.value = ""
			companyInput.value = ""
			jobInput.value = ""
			humanCheck.value = ""
			alert("Your message has been sent, thanks!")
			that.setState({buttonDisabled:false})
		})
		.catch(function (error) {
			// console.log(error);
			that.setState({buttonDisabled:false})
		})
		// console.log("--SeNd!--")
	}

	render() {
		const style = {
			color: this.state.color
		}


		return (
			<div
			className='white'
			>
			<Helmet

			title="Custom websites and mobile apps development in Barcelona by BCS"
			meta={[
				{ name: 'description', content: 'Custom websites and mobile apps development by BCS. SOftware development, development of websites, development of web applications, development of mobile apps, iOS development, Android development.' },
				{ name: 'keywords', content: 'iOS development, iOS apps development, Android development, Android apps development, websites development, web applications development' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
				{property: "og:title", content:"Custom websites and mobile apps development in Barcelona by BCS"},
				{property: "og:description", content: "Custom websites and mobile apps development by BCS. SOftware development, development of websites, development of web applications, development of mobile apps, iOS development, Android development."},
				{property: "og:image", content: "https://barcelonacodeschool.com/files/pics/students-projects-showcase.png"},
				{property: "og:url", content: "https://barcelonacodeschool.com/custom-websites-mobile-apps-development/"},
				{name: "twitter:card", content: "summary_large_image"}
				]}
				script={[
					{ "src": "https://js.stripe.com/v3/" },
					]}
					/>
					<h1 className="pageTitle">{this.state.title}</h1>
					<p>Since we have started our school back in 2015 we've been focused on teaching clean, efficient and modern code to prepare our students for becoming developers and building fast, reliable and powerfull web and mobile apps.</p>
					<p>During all these years we have accumulated a substantial pool of skilled developers and UX designers vetted by us. In 2021 we are opening a new direction – software development branch BCS CODE offering custom websites, web applications and native mobile apps for iOS and Android development.</p>
					<p>Derived from our two main bootcamps – Web / Mobile Development Bootcamp and UX/UI Design Bootcamp – we can carry out the entire process in-house, starting from problem identification, solution development, ideation, prototyping, user research, MVP; continuing with identifying the most suitable stack and tools, coding, testing and finishing with deployment, hosting or publishing your project to App Store/Google Play.</p>
					<p>We apply the same personalised approach we use in school where each student, and in this case client, matters and we focus on your project from beginning till the end. Quality is more important for us than quantity. We like the idea of doing one project at a time so we can devote all the attention to you. This is the way <img style={{height: '1em', verticalAlign: 'text-bottom'}} src={Mando}/>.</p>

					<h2>First step towards getting your website/app built is filling out this short form</h2>

					<form onSubmit={this.handleSubmit.bind(this)}>

					<input required={true} style={{
						marginBottom: '2em',
						marginTop: '0',
						border: '1px solid grey',
						display: 'inline'
					}}
					type="text" placeholder="What is your name?" name="name" />

					<input required={true} style={{
						marginBottom: '2em',
						marginTop: '0',
						border: '1px solid grey',
						display: 'inline'
					}}
					type="text" placeholder="Name of your company?" name="company" />

					<textarea required={true} style={{
						marginBottom: '2em',
						marginTop: '0',
						border: '1px solid grey'
					}}
					placeholder="Couple of words about your project, what is it about?"
					name="project"
					/>

			{/*<input required={true} style={{
				marginBottom: '2em',
				marginTop: '0',
				border: '1px solid grey'
			}}
			type="text" placeholder="Location?" name="location" />

			<p>Is the job remote or on-site? <select
			style={{
				// width: '2em',
				border: '1px solid grey',
				marginTop: '0',
				marginBottom: '2em',
			}}
			name="remote">
			<option value="remote">Remote</option>
			<option value="on-site">On-site</option>
			</select></p>
		*/}



		<input required={true}
		style={{
			border: '1px solid grey',
			marginBottom: '2em',
			marginTop: '0',
		}}
		type="email" placeholder="Your contact email?" name="email" />
		<p style={style}>If you are not a robot, how much would be 3 plus 6? <input required={true}
		style={{
			width: '2em',
			border: '1px solid grey',
			marginTop: '0',
			marginBottom: '2em',
		}}
		type="text" placeholder="" name="noRobotsAllowed" /></p>

		<button type="submit" label="Send" >Send!</button>
		</form>

		</div>
		)
}
}
// )

export default JobOffer